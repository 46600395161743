
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import RightPaymentTable from "@/components/order/RightPaymentTable.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    RightPaymentTable,
  },
  props: {
    sid: Number,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model

    const grid = ref<any>();
    const gridOptions: GridOptions = {
      title: '會員權益',
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      showFooter: true,
      columns: [
        { field: 'Number', title: '編號', showOverflow: true, sortable: true, resizable: false },
        { field: 'Rights.Name', title: '權益名稱', showOverflow: true, sortable: true, resizable: false },
        { field: 'StartDate', title: '開始日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd') },
        { field: 'EndDate', title: '結束日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd') },        
        { field: 'Enabled', title: '已啟用', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => cellValue ? '是' : '否' },
        {
          title: '繳費資訊',
          width: '100',
          slots: { default: "order" }
        },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "50%", height: "400", showFooter: true },
    }

    const showEdit = (row: any) => {
      if (row.data.Id) return true;
      return false;
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'Number',
          title: '編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '系統自動產生', clearable: true, disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'RightsId',
          title: '權益名稱',
          span: 12,
          slots: { default: "column-rights" }
        },
        {
          field: 'StartDate',
          title: '開始日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'EndDate',
          title: '結束日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'Enabled',
          title: '已啟用',
          span: 12,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        // {
        //   field: 'Order.Number',
        //   title: '訂單編號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'text', placeholder: '', disabled: true }, }
        //   ,visibleMethod: showEdit
        // },
        // {
        //   field: 'Operator.Name',
        //   title: '操作者',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'text', placeholder: '', disabled: true }, }
        //   ,visibleMethod: showEdit
        // },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }          
        },
      ],
      rules:{
        StartDate: [{ type: 'string', required: true }],
        EndDate: [{ type: 'string', required: true }],
        Enabled: [{ required: true }],
        RightsId: [{ required: true }],
      }
    }

    const rightsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      disabled: false,
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("rights/find", value), // eslint-disable-line
        query: params => model!.dispatch("rights/query", params) // eslint-disable-line
      }
    };

    const initData = (row: any, callback: any) => {
      row.Enabled = false;
      row.MemberId = props.sid;
      callback();
    }

    const remove = async(row: any, callback: any) => {
      if(row && row.Id) {
        row.DataMode = 2;
        row.Enabled = false;
        await model!.dispatch('memberRights/update', row).then((data) => {
          //console.log(data);
          grid.value.refresh();
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        });
      }
      //callback();
    }

    const paymentGrid = ref<any>()
    const paymentModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(row: any) {
        paymentModal.visible = true;
        paymentModal.selectedRow = row;
        //console.log(row)
      }
    })

    const sendMail = async(type: any, id: any) => {
      if(id){
        var msg = type == 52 ? '確定要寄送即將到期通知嗎?' : '確定要寄送到期通知嗎?';
        if(confirm(msg)) {
          await model?.dispatch('rights/sendMail', { id: id, type: type }).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          });
        }
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "查無場次資料",
        });
      }
    }

    return {
      grid,
      initData,
      gridOptions,
      formOptions,
      rightsSelectorOptions,
      paymentModal,
      paymentGrid,
      remove,
      sendMail,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      // const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      // Object.assign(row, entity)
      // const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      // row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      // row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    },    
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
