<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <vxe-form class="" ref="anyForm" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
        <vxe-form-item title="匯出欄位" field="Selected" :item-render="{}" span="24">
          <template #default="{ data }">
            <vxe-checkbox-group v-model="data.Selected">
              <vxe-checkbox class="m-1" label="1" content="會員編號"></vxe-checkbox>
              <vxe-checkbox class="m-1" label="2" content="公司"></vxe-checkbox>
              <vxe-checkbox class="m-1" label="3" content="姓名"></vxe-checkbox>
              <vxe-checkbox class="m-1" label="4" content="性別"></vxe-checkbox>
              <vxe-checkbox class="m-1" label="5" content="部門"></vxe-checkbox>
              <vxe-checkbox class="m-1" label="6" content="職稱"></vxe-checkbox>
              <vxe-checkbox class="m-1" label="7" content="帳號(Email)"></vxe-checkbox>
              <vxe-checkbox class="m-1" label="8" content="上市櫃"></vxe-checkbox>
              <vxe-checkbox class="m-1" label="9" content="行業別"></vxe-checkbox>
              <vxe-checkbox class="m-1" label="10" content="通訊地址"></vxe-checkbox>
              <vxe-checkbox class="m-1" label="11" content="如何得知MII金屬情報網"></vxe-checkbox>
              <vxe-checkbox class="m-1" label="12" content="參與的活動"></vxe-checkbox>
            </vxe-checkbox-group>
          </template>
        </vxe-form-item>
        <vxe-form-item :item-render="{}" span="3">
          <template #default>
            <vxe-button type="buttom" @click="table.selectedAll" status="primary" content="全選">
            </vxe-button>
            <vxe-button type="submit" status="primary" content="匯出">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    visible: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: [{ field: 'Type', order: 'asc' }, { field: "Level", order: 'asc' }]
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      save() {
        emit('save', table.formData.Selected)
        // if (table.formData.Type != null && table.formData.CopyType != null && table.formData.Type >= 0 && table.formData.CopyType >= 0) {
        //   emit('save', { type: table.formData.Type, copyType: table.formData.CopyType });
        // }
      },
      selectedAll() {
        table.formData.Selected = [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12' ]
      },
      rules: {
        Selected: [{ required: true }],
      }
    })

    watch(() => props.visible, async(value) => {
      if(value) {
        table.formData = {} as any
      }      
    }, { immediate: true });

    const anyForm = ref<any>();

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      model,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
