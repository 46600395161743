/* eslint-disable */

// 縣市行政區
const list = require("@/assets/json/city.json");
const road = require("@/assets/json/road.json");
export function cityList() {    
    return list.map((e: any)=> { return { value: e.name, label:e.name } } );
}

export function districtList(city: any) {
    var result = list.filter((e: any)=> e.name == city);
    return (result[0]).districts.map((e: any)=> { return { value: e.name, label:e.name, zip: e.zip } } );
}

export function GetZip(city: any, district: any) {
    var result = list.filter((e: any)=> e.name == city);   
    return result[0].districts.filter((e: any)=> e.name == district).map((e: any)=> e.zip)[0];
}

export function regionList(city: any, district: any) {
    //console.log(city, district);
    var cityJson = road.find((e: any) => e.CityName == city);
    //console.log(cityJson);
    var districtJson = cityJson?.AreaList?.find((e: any) => e.AreaName == district);
    //console.log(districtJson);
    var result = districtJson?.RoadList?.map((e: any)=> { return { value: e.RoadName, label:e.RoadName } } );
    //console.log(result);
    return result;
}